<template>
  <div class="userinfo-orderList">
    <div class="title pointer flex-center-start">
      <i class="el-icon-arrow-left" @click="back"></i>
      {{ $t('guan-zhu-dian-pu-0') }}
    </div>

    <div class="follow-list">
      <div class="shop flex-start" v-for="(item, i) in shopList" :key="i">
        <div class="img">
          <img :src="item.Shop.Logo" alt="" />
        </div>
        <div class="right">
          <div class="line flex-center-between">
            <div class="name">{{ item.Shop.Name }}</div>
            <div>
              <el-button class="btn1">{{ $t('qu-xiao-guan-zhu') }}</el-button>
            </div>
          </div>
          <div class="line">
            {{ $t('yi-shou') }}
            {{ item.Shop.TotalDeal }}
          </div>
          <div class="line flex-center-between">
            <div>
              {{ $t('ping-fen') }}
              {{ item.Shop.GoodsScore }}
            </div>
            <div>
              <el-button class="btn2" @click="toShop(item)">{{
                $t('fang-wen-shang-dian')
              }}</el-button>
            </div>
          </div>
        </div>
      </div>

      <div class="empty-box flex-column-center" v-if="shopList.length == 0">
        <img :src="emptyUrl" alt="" />
        <span>{{ $t('zan-wu-shu-ju') }}</span>
      </div>
    </div>

    <div class="flex-center user-pager">
      <el-pagination
        background
        @current-change="currentChange"
        :current-page="page.current"
        :page-size="page.size"
        layout=" prev, pager,  next"
        :total="page.total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getShopFollowList } from '@/api/shop'
import goodsList from '@/components/goodsList'
export default {
  components: {
    goodsList
  },
  data() {
    return {
      shopList: [],
      emptyUrl: require('@/assets/imgs/emtpy.svg'),
      page: {
        size: 10,
        current: 1,
        total: 0
      }
    }
  },
  mounted() {
    this.initFollow()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    initFollow() {
      getShopFollowList({
        current: this.page.current,
        pageSize: this.page.size
      }).then((res) => {
        this.shopList = res.data.Items
        this.page.total = res.data.Pagination.totalRecords
      })
    },
    changeTab(i) {
      this.tabIndex = i
    },
    currentChange(page) {
      this.page.current = page
      this.initFollow()
    },
    toShop(data) {
      this.$router.push({
        name: 'shopInfo',
        query: {
          id: data.ID
        }
      })
    }
  }
}
</script>